import * as React from "react"
import PropTypes from "prop-types"

const AwardsList = ({ awardata }) => {
  return (
    <div className="pdetails_discp_content">
      <h4 className="black-dash-small">Recognition</h4>
      <div className="pdet_award_group">
        {awardata.map((award, indx) => {
          return (
            <p key={`awrd${indx}`} className="pdet_award">
              <span>{award.name}</span> <br /> {award.date}
            </p>
          )
        })}
      </div>
    </div>
  )
}

AwardsList.propTypes = {
  awardata: PropTypes.array,
}

AwardsList.defaultProps = {
  awardata: [],
}

export default AwardsList
