import * as React from "react"
import PropTypes from "prop-types"

const LocationList = ({ locdata }) => {
  return (
    <div className="pdetails_discp_content">
      <h4 className="black-dash-small">Locations</h4>
      <div className="pdet_location_group">
        {locdata.map((loc, indx) => {
          return (
            <p key={`loc${indx}`} className="pdet_location">
              {loc.venue} <br /> <span>{loc.area}</span>
            </p>
          )
        })}
      </div>
    </div>
  )
}

LocationList.propTypes = {
  locdata: PropTypes.array,
}

LocationList.defaultProps = {
  locdata: [],
}

export default LocationList
